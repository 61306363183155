import React, { useState } from "react"
import arrow from "../../images/arrow_right.svg"
import "./reources.styles.scss"
import Carousel from "react-elastic-carousel"
import { Link, graphql, StaticQuery } from "gatsby"
import "../../styles.css"

const Reecent = props => {
  const [value, onChange] = useState("")

  const { data } = props
  const { edges: posts } = data.allMarkdownRemark

  const newPosts =
    value === ""
      ? posts
      : posts.filter(post =>
          post.node.frontmatter.blogtitle.toLowerCase().includes(value)
        )

  console.log(newPosts)
  return (
    <div style={{ background: "#E5E5E5", paddingBottom: "60px" }} id="topblog">
      <div className="resource_head">
        <div className="container">
          <div style={{ marginTop: "60px" }}>
            {" "}
            <span className="ourtitle">
              Build faster & smarter today.
            </span>
          </div>
          <hr />
        </div>
      </div>
      <div className="resource_title">
        <h1>
            LeanML Resources
        </h1>
        <hr style={{ margin: "0 auto" }} />
        <p>
          <span className="ourtext">
            We’ve compiled LeanML principles and our wealth of experience with ML
            all into one place.
          </span>
        </p>
      </div>
      <div className="container resource-body reecent">
        <div className="carousel" style={{ marginTop: "80px" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            <span className="ourtitle">
              Most Recent
            </span>
          </p>
          <hr />
          <Carousel
            itemsToShow={3}
            style={{ marginTop: "50px" }}
            breakPoints={[
              { width: 200, itemsToShow: 1 },
              { width: 650, itemsToShow: 1 },
              { width: 750, itemsToShow: 2 },
              { width: 1150, itemsToShow: 3 },
            ]}
          >
            {posts &&
              posts.map(({ node: post }) => (
                <Link to={`/resources/${post.frontmatter.path}`}>
                  <div className="news-card blog_news">
                    <div
                      style={{
                        backgroundImage:
                          "url(" + post.frontmatter.thumbnail.publicURL + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "280px",
                      }}
                      className="blog_bg"
                    ></div>{" "}
                    <div
                      style={{
                        maxWidth: "400px",
                        textAlign: "left",
                        padding: "10px 20px",
                        minHeight: "120px",
                      }}
                    >
                      <h5>
                        <b>
                          <span className="ourtitle">
                            {post.frontmatter.blogtitle}
                          </span>
                        </b>
                      </h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 20px",
                      }}
                    >
                      <div className="date" style={{ fontSize: "14px" }}>
                        <span className="ourtext">
                          {post.frontmatter.date}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#2C4888",
                            textDecoration: "none",
                            paddingRight: "3px",
                          }}
                        >
                          <span className="ourtext">
                          Read more
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <img src={arrow} alt="right arrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </Carousel>
        </div>
        <div className="all_resources">
          <div>
            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              <span className="ourtext">
                All Resources{" "}
              </span>
            </p>

            <hr />
          </div>
          <div className="search" style={{ width: "400px", maxWidth: "100%" }}>
            <input
              type="search"
              placeholder="Search"
              onChange={e => {
                onChange(e.currentTarget.value)
              }}
              value={value}
              style={{ width: "100%" }}
              className="ourtext"
            />
          </div>
        </div>
        {newPosts &&
          newPosts.map(({ node: post }) => (
            <Link to={post.frontmatter.path}>
              <div className="blog_card">
                <div
                  className="images"
                  style={{
                    backgroundImage:
                      "url(" + post.frontmatter.thumbnail.publicURL + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
                <div className="content_blog">
                  <div className="date">{post.frontmatter.date}</div>
                  <div className="title">
                    {post.frontmatter.blogtitle}
                    <hr style={{ marginTop: "10px" }} />
                  </div>
                  <div className="para">{post.excerpt}</div>
                  <div className="parent_cat">
                    {post.frontmatter.categories.map(cat => (
                      <div className="category"> {cat}</div>
                    ))}
                  </div>

                  <div
                    className="date"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#2C4888",
                      textDecoration: "none",
                    }}
                  >
                      Read more{" "}
                    <span
                      style={{
                        verticalAlign: "middle",
                        margin: "0 auto",
                        display: "inline-flex",
                      }}
                    >
                      <img src={arrow} alt="right arrow" />
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {}
        ) {
          edges {
            node {
              excerpt(pruneLength: 450)
              id
              frontmatter {
                path
                blogtitle
                categories
                author
                thumbnail {
                  publicURL
                }
                date(formatString: "MM.DD.YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Reecent data={data} count={count} />}
  />
)
