import React from "react"
import { globalHistory } from "@reach/router"
import Reecent from "../components/blog/Reecent"
import Header from "../components/header/header.component"
import Footer from "../components/footer/footer.component"
import SEO from "../components/seo"

const Blog = () => {
  return (
    <div>
      <SEO title="Resources " />
      <Header url={globalHistory.location.pathname} />
      <Reecent />
      <Footer url={globalHistory.location.pathname} />
    </div>
  )
}

export default Blog
